<template>
  <div class="shop">
    <!--<div class="header">
      <div class="mobile-wrapper">
        <div class="header-background" :style="{backgroundImage: 'url(https://ebikeworldfederation.com/images/shop/EBWF_Wirsindebike_Shop_Headerbild_01.jpg)'}">
          <div class="header-title-container">
            <h1>{{ $t("menu.shop") }}</h1>
          </div>
        </div>
      </div>
    </div>

    <v-main class="shop-container">
      <v-container fluid>
        <v-layout class="align-center justify-center">
          <v-col cols="12" sm="12" md="12" lg="12" xl="8">
            <div id="my-store-28500096"/>
            <div>
              <script2 data-cfasync="false" type="text/javascript"
                       src="https://app.ecwid.com/script.js?28500096&data_platform=code&data_date=2020-04-24"
                       charset="utf-8"/>
              <script2 type="text/javascript">
                xProductBrowser("categoriesPerRow=3","views=grid(20,3) list(60) table(60)","categoryView=grid",
                "searchView=list","id=my-store-28500096");
              </script2>
            </div>
          </v-col>
        </v-layout>
      </v-container>
    </v-main>-->
    <v-overlay :value="true">
      <v-progress-circular
          color="primary"
          indeterminate
          size="64"
      ></v-progress-circular>
    </v-overlay>
  </div>
</template>

<script>
  import Vue from 'vue';
  import VS2 from 'vue-script2';

  Vue.use(VS2);

  export default {
    name: 'shop',
    created() {
      // window.location.href = 'https://shop.ebikeworldfederation.com/';
      /* this.globalEvents.$on('goToElement', (selector) => {
        if (selector !== undefined) {
          this.$vuetify.goTo(selector, {
            duration: 0,
            offset: 70,
            easing: 'easeInOutCubic',
          });
        }
      }); */
    },
  };
</script>

<style lang="scss" scoped>
  .shop {
    display: block;
    height: 100vh;
  }
</style>
